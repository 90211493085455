
























import { Component, Prop, Vue } from 'vue-property-decorator'
import _ from 'lodash'
import { AgGridVue } from 'ag-grid-vue'
import { GridApi } from 'ag-grid-community'
import AgGridFactory from '@/factories/agGridFactory'
import OrganizationsAPI from '@/api/requests/organizations'
import TeacherAPI from '@/api/requests/teacher'
import {
  PermissionManager,
  permissions
} from '@/api/permissions/PermissionManager'
import { Content } from '@/types/interfaces/content'
import SetFilter from '@/components/table/filters/SetFilter'

@Component({
  components: { AgGridVue, SetFilter }
})
export default class ChangeParticipantsModal extends Vue {
  @Prop(Object) content!: Content
  private gridApi?: GridApi

  private canChangeListOfParticipants = false

  private participants: any[] = []
  private rowData: any[] = []
  private selectedUsers: any[] = []
  private columnDefs = [
    {
      headerName: 'Статус',
      field: 'participation',
      colId: 'participation',
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      width: 50,
      suppressSizeToFit: true,
      filterFramework: 'SetFilter',
      valueGetter(node: any) {
        return node.node.selected
      },
      valueFormatter() {
        return ''
      },
      filterParams: {
        keys: [true, false],
        keyFormatter(key: any) {
          return key ? 'Отмечены' : 'Не отмечены'
        }
      }
    },
    {
      headerName: 'Фамилия',
      field: 'surname',
      colId: 'surname',
      minWidth: 120
    },
    {
      headerName: 'Имя',
      field: 'name',
      colId: 'name',
      minWidth: 100
    },
    {
      headerName: 'Отчество',
      field: 'midname',
      colId: 'midname',
      minWidth: 80
    },
    {
      headerName: 'Организации',
      field: 'organizations',
      colId: 'organizations',
      minWidth: 300,
      tooltipField: 'organizations'
    }
  ]

  private gridOptions = {
    ...AgGridFactory.getDefaultGridOptions(),
    defaultColDef: {
      ...AgGridFactory.getDefaultGridOptions().defaultColDef,
      filterParams: {
        buttons: ['reset']
      },
      frameworkComponents: { SetFilter }
    },
    pagination: true,
    paginationPageSize: 10,
    suppressRowClickSelection: true,
    rowSelection: 'multiple',
    onGridReady: this.onGridReady
  }

  private onGridReady({ api }: { api: GridApi }) {
    this.gridApi = api
    this.updateSelection()
  }

  private async changeParticipants() {
    this.selectedUsers = (this.gridApi?.getSelectedNodes() ?? []).map(
      (element: any) => element.data.id
    )
    await TeacherAPI.setParticipants(this.content.id, this.selectedUsers)
    this.$emit('changeParticipants')
  }

  private async updateSelection() {
    this.participants = (
      await TeacherAPI.getParticipantsFromMyOrganizations(this.content.id)
    ).data
    const toSelectUsers = _.intersectionBy(
      this.participants,
      this.rowData,
      'id'
    )
    this.selectedUsers = this.selectedUsers.concat(
      toSelectUsers.map((element: any) => this.selectedUsers.push(element.id))
    )
    this.gridApi?.forEachNode(node => {
      if (this.selectedUsers.includes(node.data.id)) {
        node.setSelected(true)
      }
    })
  }

  private async created() {
    const ability = PermissionManager.common()
    this.canChangeListOfParticipants = ability.can(
      permissions.changeListOfParticipants,
      undefined
    )
    if (this.canChangeListOfParticipants) {
      const response = await OrganizationsAPI.getUsersFromOrganizations()
      this.rowData = response.data.map((element: any) => {
        element.organizations = element.organizations
          .map((org: any) => org.title)
          .join(', ')
        return element
      })
    }
  }
}
