




























import { Component, Prop, Vue } from 'vue-property-decorator'
import { AgGridVue } from 'ag-grid-vue'
import { GridApi } from 'ag-grid-community'
import AgGridFactory from '@/factories/agGridFactory'
import { toastMapper } from '@/store/modules/toast'
import ChangeParticipantsModal from '@/components/common/modal/ChangeParticipantsModal.vue'
import { EventParticipantsDataSource } from '@/api/datasource/eventParticipnats'
import {
  PermissionManager,
  permissions
} from '@/api/permissions/PermissionManager'
import { organizationFormatter } from '@/utils/formatters'
import { Content } from '@/types/interfaces/content'

const Mapper = Vue.extend({
  methods: {
    ...toastMapper.mapActions(['pushToast'])
  }
})

@Component({
  components: { ChangeParticipantsModal, AgGridVue }
})
export default class ListOfParticipants extends Mapper {
  @Prop(Object) content!: Content

  private dataSource: EventParticipantsDataSource | undefined
  private canChangeListOfParticipants = false

  private gridApi?: GridApi

  private async created() {
    const ability = PermissionManager.common()
    this.canChangeListOfParticipants = ability.can(
      permissions.changeListOfParticipants,
      undefined
    )
    this.dataSource = new EventParticipantsDataSource(this.content.id)
  }

  private changeListOfParticipants() {
    this.$bvModal.show('changeParticipantsModalId')
  }

  private async changeParticipants() {
    if (this.dataSource && this.gridApi) {
      this.gridApi.purgeInfiniteCache()
    }
    this.pushToast({
      time: 3,
      title: 'Обновление списка участников',
      message: 'Список участников события успешно обновлен!'
    })
  }

  private columnDefs = [
    {
      headerName: 'Фамилия',
      field: 'surname',
      colId: 'surname',
      minWidth: 100
    },
    {
      headerName: 'Имя',
      field: 'name',
      colId: 'name',
      minWidth: 100
    },
    {
      headerName: 'Отчество',
      field: 'midname',
      colId: 'midname',
      minWidth: 100
    },
    {
      headerName: 'Организации',
      field: 'organizations',
      colId: 'organizations',
      minWidth: 100,
      valueFormatter: organizationFormatter
    }
  ]

  private onGridReady({ api }: { api: GridApi }) {
    this.gridApi = api
  }

  private gridOptions = {
    ...AgGridFactory.getDefaultGridOptions(),
    defaultColDef: {
      ...AgGridFactory.getDefaultGridOptions().defaultColDef,
      filterParams: {
        buttons: ['reset']
      }
    },
    pagination: true,
    paginationPageSize: 10,
    rowModelType: 'infinite',
    onGridReady: this.onGridReady
  }
}
