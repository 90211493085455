

























































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import LikeEditor from '@/components/content/helpers/LikeEditor.vue'
import ListOfParticipants from '@/components/teacher/ListOfParticipants.vue'
import { imagePreview } from '@/config/default/defaultImages'
import { ContentAdapter } from '@/api/adapters/content'
import {
  organizationBadges,
  typeOrganization
} from '@/config/types/typeOrganization'
import { contentType } from '@/config/content/contentStatus'
import {
  PermissionManager,
  permissions
} from '@/api/permissions/PermissionManager'
import _ from 'lodash'
import { userMapper } from '@/store/modules/user'
import { Content } from '@/types/interfaces/content'

const Mapper = Vue.extend({
  computed: {
    ...userMapper.mapGetters(['isAuthenticated'])
  }
})
@Component({
  components: { LikeEditor, ListOfParticipants }
})
export default class SingleContent extends Mapper {
  @Prop(Object) content!: Content
  private canSeeViewsLikes = false
  private canSeeParticipants = false

  private imagePreview_ = imagePreview
  private typeOrganization_ = typeOrganization
  private organizationBadges_ = organizationBadges
  private contentCopy: any = {}
  private contentType_ = contentType

  private get castedDate() {
    return ContentAdapter.changeDateFormat(
      this.content.type,
      this.content.eventDate
    )
  }

  private formatContent() {
    this.contentCopy = _.cloneDeep(this.content)
    if (this.contentCopy.type === contentType.event) {
      this.contentCopy.eventDate = ContentAdapter.changeDateFormat(
        this.contentCopy.type,
        this.contentCopy.eventDate
      )
    }
  }

  @Watch('content')
  private onChangeContent() {
    this.formatContent()
  }

  private created() {
    const ability = PermissionManager.common()
    this.canSeeViewsLikes = ability.can(permissions.seeViewsLikes, undefined)
    this.canSeeParticipants = ability.can(
      permissions.seeParticipants,
      undefined
    )

    this.formatContent()
  }

  private mounted() {
    this.openVkWidget()
  }

  private addVkWidget() {
    this.loadVkWidget().then(() => {
      this.addObservers()
    })
  }
  private loadVkWidget() {
    return new Promise<void>(resolve => {
      const widget = document.createElement('script')
      widget.onload = () => {
        resolve()
      }
      widget.async = true
      widget.type = 'text/javascript'
      widget.src = 'https://vk.com/js/api/share.js?95'
      widget.charset = 'windows-1251'
      document.head.appendChild(widget)
    })
  }
  private addObservers() {
    document.getElementById(
      'vk-share-widget'
    )!.innerHTML = window.VK.Share.button(false, {
      type: 'custom',
      text:
        '<img src="https://vk.com/images/share_32.png" width="32" height="32"/>'
    })
  }

  public openVkWidget() {
    if (
      this.isAuthenticated &&
      this.contentCopy.type != this.contentType_.organization
    ) {
      this.addVkWidget()
    }
  }
}
