

















import { Vue, Component } from 'vue-property-decorator'

@Component
export default class SaveContentModal extends Vue {
  //TODO Prop
  //@Prop({ type: Number, required: true }) private readonly id!: number
  private onSaveContent() {
    // TODO сохранение организации
    this.$emit('modalSave')
  }
}
