var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-3 wrapper"},[_c('b-row',[_c('b-col',{staticClass:"title"},[_c('h2',[_vm._v(_vm._s(_vm.contentCopy.title))])])],1),_c('hr'),_c('b-row',{staticClass:"d-flex card-body"},[_c('b-col',{staticClass:"image-container"},[_c('b-card-img',{staticClass:"b-card-img-class",attrs:{"src":_vm.contentCopy.avatarUrl,"alt":"Фото"},on:{"error":function (e) { return (e.target.src = _vm.imagePreview_); }}}),(
          Object.values(_vm.typeOrganization_).includes(
            _vm.contentCopy.organizationType
          )
        )?_c('b-badge',{staticClass:"mt-3 badge",attrs:{"variant":_vm.organizationBadges_[_vm.contentCopy.organizationType]}},[_vm._v(_vm._s(_vm.contentCopy.organizationType))]):_vm._e()],1),_c('b-col',{staticClass:"short-info-container"},[_c('div',{staticClass:"short-info"},[_vm._v(" "+_vm._s(_vm.contentCopy.shortDescription)+" ")]),(
          _vm.contentCopy.type === _vm.contentType_.event ||
            _vm.contentCopy.type === _vm.contentType_.news
        )?_c('b-row',{staticClass:"ml-1 time-like-data"},[_c('div',{staticClass:"mb-2 mr-4"},[_c('font-awesome-icon',{attrs:{"icon":['far', 'clock']}}),_vm._v(" "+_vm._s(_vm.contentCopy.eventDate)+" ")],1),(!_vm.canSeeViewsLikes)?_c('div',{staticClass:"mr-4"},[_vm._v(" "+_vm._s(_vm.content.views)+" "),_c('font-awesome-icon',{attrs:{"icon":['fas', 'eye']}})],1):_vm._e(),(
            _vm.isAuthenticated && _vm.contentCopy.type != _vm.contentType_.organization
          )?_c('span',{staticClass:"mr-4",attrs:{"title":"Поделиться ВКонтакте","id":"vk-share-widget"}}):_vm._e(),(_vm.contentCopy.id)?_c('LikeEditor',{staticClass:"mr-3",attrs:{"contentId":_vm.contentCopy.id,"likes":_vm.contentCopy.likes,"dislikes":_vm.contentCopy.dislikes,"clicked":_vm.contentCopy.userReaction}}):_vm._e()],1):_vm._e(),(
          Object.values(_vm.typeOrganization_).includes(
            _vm.contentCopy.organizationType
          )
        )?_c('div',{staticClass:"address"},[_c('div',[_c('font-awesome-icon',{attrs:{"icon":['fa', 'map-marked-alt']}}),_vm._v(" "+_vm._s(_vm.contentCopy.address)+" ")],1)]):_vm._e()],1)],1),(_vm.contentCopy.fullDescription)?_c('div',[_c('hr'),_c('div',{staticClass:"full-info mt-2",domProps:{"innerHTML":_vm._s(_vm.contentCopy.fullDescription)}})]):_vm._e(),(!_vm.canSeeParticipants && _vm.content.type === _vm.contentType_.event)?_c('div',[_c('hr'),(_vm.content.id)?_c('ListOfParticipants',{attrs:{"content":_vm.content}}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }