















import { Vue, Component } from 'vue-property-decorator'

@Component
export default class CancelContentModal extends Vue {
  private onCancelContent() {
    this.$emit('modalCancel')
  }
}
