



















import { Vue, Prop, Component } from 'vue-property-decorator'
import {
  contentStatus,
  contentStatusBadges,
  contentStatusTranslation,
  publicationButtonTranslation
} from '@/config/content/contentStatus'

@Component
export default class PublicationButtons extends Vue {
  @Prop(String) status!: contentStatus

  private contentStatusBadges_ = contentStatusBadges
  private contentStatusTranslation_ = contentStatusTranslation
  private publicationButtonTranslation_ = publicationButtonTranslation

  private onChangeStatus() {
    this.$emit('onChangeStatus')
  }
}
