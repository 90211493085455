var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.content)?_c('div',{staticClass:"base-padding"},[_c('div',{staticClass:"mr-2 d-flex justify-content-end buttons"},[(
        (_vm.viewContentManagerButtons ||
          (_vm.viewTeacherButton &&
            this.content.type === _vm.contentType_.event &&
            _vm.isOrganizations) ||
          this.id === 'new') &&
          (this.content.type === _vm.contentType_.news ||
            this.content.type === _vm.contentType_.event)
      )?_c('PublicationButtons',{attrs:{"status":_vm.content.status},on:{"onChangeStatus":_vm.buttonPublicate}}):_vm._e(),_c('ControlButtons',{attrs:{"statusButton":_vm.statusButton,"content":_vm.content},on:{"edit":_vm.buttonEdit,"save":_vm.buttonSave,"delete":_vm.buttonDelete,"close":_vm.buttonClose,"cancel":_vm.buttonCancel}})],1),(
      _vm.statusButton === _vm.typeButtons_.add || _vm.statusButton === _vm.typeButtons_.edit
    )?_c('AddEditContent',{attrs:{"statusButton":_vm.statusButton,"content":_vm.content}}):_vm._e(),(
      _vm.statusButton === _vm.typeButtons_.view || _vm.statusButton === _vm.typeButtons_.save
    )?_c('SingleContent',{attrs:{"content":_vm.content}}):_vm._e(),_c('PublicateContentModal',{attrs:{"status":_vm.content.status},on:{"modalPublicate":_vm.modalPublicate}}),_c('SaveContentModal',{on:{"modalSave":_vm.modalSave}}),_c('CancelContentModal',{on:{"modalCancel":_vm.modalCancel}}),_c('DeleteContentModal',{attrs:{"modalId":("deleteContentModalId-" + (_vm.content.id)),"content":_vm.content},on:{"modalDelete":_vm.deleteContentButton}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }