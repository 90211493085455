











































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { FormOptions, FormSchema } from 'vue-form-generator'
import LoadFileButton from '@/components/ui/LoadFileButton.vue'
import { imagePreview } from '@/config/default/defaultImages'
import { typeOrganization } from '@/config/types/typeOrganization'
import { onImageInput, isValidLoadedFile } from '@/utils/images'
import { toastMapper } from '@/store/modules/toast'
import { statusButton } from '@/config/content/statusButton'
import { fileFormats } from '@/config/default/extensions'
import { contentType } from '@/config/content/contentStatus'
import { maxLengthField } from '@/config/default/user'
import validators from '@/utils/validators'
import { userMapper } from '@/store/modules/user'
import { dictMapper } from '@/store/modules/dicts'
import ContentFactory from '@/factories/content'
import _ from 'lodash'
import { isUserOrganizations } from '@/utils/organizations'
import { Content } from '@/types/interfaces/content'

const Mapper = Vue.extend({
  computed: {
    ...userMapper.mapGetters([
      'userOrganizations',
      'isTeacher',
      'isContentManager'
    ])
  },
  methods: {
    ...toastMapper.mapActions(['pushToast']),
    ...dictMapper.mapActions(['fetchAllOrganizations'])
  }
})

@Component({
  components: {
    LoadFileButton
  }
})
export default class AddEditContent extends Mapper {
  @Prop(Object) content!: Content
  @Prop(String) statusButton!: statusButton

  private isUpdateContent = false
  private imagePreview_ = imagePreview
  private typeOrganization_ = typeOrganization
  private contentType_ = contentType
  private selectedOrganizations: any[] = []
  private contentCopy: any = null
  private constOrganization = 'Все'

  @Watch('content', { deep: true })
  private onChangeContent() {
    this.contentCopy = _.cloneDeep(this.content)
    this.isUpdateContent = true

    if (
      this.content.type === this.contentType_.event &&
      this.content.id != null
    ) {
      this.organizationsSchema.fields![0].disabled = this.blockField
    }
  }
  @Watch('contentCopy', { deep: true })
  private onChangeContentCopy() {
    if (!this.isUpdateContent) {
      this.content.organizations = this.contentCopy.organizations
    } else {
      this.isUpdateContent = false
    }
  }

  private created() {
    this.contentCopy = _.cloneDeep(this.content)
    this.selectedOrganizations = _.cloneDeep(this.userOrganizations)
    if (this.isContentManager && this.isTeacher) {
      this.selectedOrganizations.unshift(
        ContentFactory.contentManagerEventsObject()
      )
    }

    if (
      this.content.type === this.contentType_.event &&
      this.content.id != null
    ) {
      this.organizationsSchema.fields![0].disabled = this.blockField
    }
    if (this.isTeacher) {
      this.setOrganizations()
    }
    if (!this.content.avatarUrl) {
      this.content.avatarUrl = this.imagePreview_
    }
  }

  private schemaClassSchoolAddress: FormSchema = {
    fields: [
      {
        type: 'bootstrap',
        inputType: 'text',
        placeholder: 'Гимназия 75',
        label: 'Организация',
        model: 'title',
        styleClasses: 'wm-100',
        required: true,
        validator(value, field, model) {
          if (model.title?.length === 0) {
            return ['Это поле обязательно!']
          }
          if (model.title?.length > maxLengthField) {
            return [`Название содержит больше ${maxLengthField} символов`]
          }
          return []
        }
      },
      {
        type: 'multiselect',
        inputType: 'text',
        model: 'organizationType',
        styleClasses: 'wm-100',
        label: 'Тип',
        options: Object.values(this.typeOrganization_),
        required: true
      },
      {
        type: 'bootstrap',
        inputType: 'text',
        placeholder: 'г.Санкт-Петербург, Невский пр-кт, д.16, к.2',
        label: 'Адрес',
        model: 'address',
        styleClasses: 'wm-100',
        validator(value, field, model) {
          if (model.title?.length > maxLengthField) {
            return [`Адрес содержит больше ${maxLengthField} символов`]
          }
          return []
        }
      }
    ]
  }

  private organizationsSchema: FormSchema = {
    fields: [
      {
        type: 'multiselect',
        label: 'Организация',
        model: 'organizations',
        placeholder: 'Выберите организацию',
        options: [],
        trackBy: 'id',
        multiple: true,
        required: true,
        validator(value, field, model) {
          if (
            model.organizations.find(
              (el: any) => el.title === this.constOrganization
            ) &&
            model.organizations?.length > 1
          ) {
            return [
              `Чтобы событие было доступно всем, выберите только вариант "${this.constOrganization}"`
            ]
          }
          return []
        },
        customLabel({ title }: any) {
          return title ? title : 'Выберите значение'
        }
      }
    ]
  }
  private schemaDescriptions: FormSchema = {
    fields: [
      {
        type: 'bootstrapTextArea',
        label: 'Краткое описание',
        model: 'shortDescription',
        styleClasses: 'wm-100',
        rows: '4',
        validator(value, field, model) {
          if (model.shortDescription?.length > maxLengthField) {
            return [
              `Краткое описание содержит больше ${maxLengthField} символов`
            ]
          }
          return []
        }
      },
      {
        type: 'CKEditor',
        label: 'Полное описание',
        model: 'fullDescription',
        styleClasses: 'wm-100',
        rows: '4'
      }
    ]
  }
  private eventDateSchema: FormSchema = {
    fields: [
      {
        type: 'bootstrap',
        inputType: 'text',
        placeholder: 'Напишите заголовок',
        label: 'Заголовок',
        model: 'title',
        styleClasses: 'wm-100',
        required: true,
        validator(value, field, model) {
          if (model.title?.length === 0) {
            return ['Это поле обязательно!']
          }
          if (model.title?.length > maxLengthField) {
            return [`Заголовок содержит больше ${maxLengthField} символов`]
          }
          return []
        }
      },
      {
        type: 'datetimePicker',
        model: 'eventDate',
        styleClasses: 'wm-100 mt-3',
        label: 'Дата и время',
        validator: validators.required,
        required: true
      }
    ]
  }
  private newsDateSchema: FormSchema = {
    fields: [
      {
        type: 'bootstrap',
        inputType: 'text',
        placeholder: 'Напишите заголовок',
        label: 'Заголовок',
        model: 'title',
        styleClasses: 'wm-100',
        required: true,
        validator(value, field, model) {
          if (model.title?.length === 0) {
            return ['Это поле обязательно!']
          }
          if (model.title?.length > maxLengthField) {
            return [`Заголовок содержит больше ${maxLengthField} символов`]
          }
          return []
        }
      },
      {
        type: 'datepicker',
        label: 'Дата',
        model: 'eventDate',
        styleClasses: 'wm-100 mt-3',
        validator: validators.required,
        required: true
      }
    ]
  }

  private async deleteClicked() {
    this.content.avatarUrl = this.imagePreview_
  }

  private async onClickImage(e: any) {
    const { isOk, error } = isValidLoadedFile(
      e.target.files[0],
      fileFormats.image
    )
    if (e.target.files && e.target.files[0] && isOk) {
      this.content.avatarUrl = await onImageInput(e.target.files[0])
    } else {
      this.pushToast({
        error: true,
        time: 5,
        title: 'Ошибка загрузки файла',
        message: error
      })
    }
  }

  private isOrganizationContent() {
    return (
      this.content.organizations.length > 0 &&
      this.content.organizations.find(
        (el: any) => el.title != this.constOrganization
      )
    )
  }

  private get fieldOrganizations() {
    return (
      this.content.type === this.contentType_.event &&
      (this.isTeacher ||
        (this.isContentManager && this.isOrganizationContent()))
    )
  }

  private setOrganizations() {
    this.organizationsSchema.fields!.find(
      item => item.model === 'organizations'
    )!.options = this.selectedOrganizations
  }

  get blockField(): boolean {
    return (
      (isUserOrganizations(
        this.userOrganizations,
        this.content.organizations
      ) &&
        this.isContentManager &&
        this.isTeacher &&
        this.content.organizations.find(
          (el: any) => el.title != this.constOrganization
        )) ||
      (this.isContentManager && !this.isTeacher)
    )
  }

  private options: FormOptions = {
    validateAfterLoad: true,
    validateAfterChanged: true,
    validateAsync: true
  }
}
