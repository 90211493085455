import { http } from '@/api/requests/httpAxios'
import { AxiosPromise } from 'axios'
import createURLParams from '@/api/datasource/createURLParams'

export default class TeacherAPI {
  public static getParticipants(
    id: number,
    limit: number,
    offset: number,
    sort: { [k: string]: any } | null,
    filter: { [k: string]: any } | null
  ): AxiosPromise {
    const params = createURLParams(limit, offset, sort, filter)
    return http.get(`/participants/${id}`, { params })
  }

  public static getParticipantsFromMyOrganizations(id: number): AxiosPromise {
    return http.get(`/organizations/participants/${id}`)
  }

  public static setParticipants(id: number, userIds: number[]): AxiosPromise {
    return http.put(`/events/${id}/participants`, { userIds })
  }
}
