















import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { statusButton } from '@/config/content/statusButton'
import { contentType } from '@/config/content/contentStatus'
import {
  PermissionManager,
  permissions
} from '@/api/permissions/PermissionManager'
import { userMapper } from '@/store/modules/user'
import { isUserOrganizations } from '@/utils/organizations'
import { Content } from '@/types/interfaces/content'

const Mapper = Vue.extend({
  computed: {
    ...userMapper.mapGetters(['isTeacher', 'userOrganizations'])
  }
})
@Component
export default class ControlButtons extends Mapper {
  @Prop(String) statusButton!: statusButton
  @Prop(Object) readonly content!: Content

  private canEditOrganization = false
  private canEditNewsEvents = false
  private isOrganizations = false

  @Watch('content')
  private onChangeContent() {
    this.isOrganizations = isUserOrganizations(
      this.userOrganizations,
      this.content.organizations
    )
    this.rebuildButtons()
  }
  @Watch('statusButton')
  private onStatusButtonChanged() {
    this.rebuildButtons()
  }

  private typeButton_ = statusButton
  buttons = [] as {
    title: string
    icons: string[]
    name: string
    variant: string
    condition: Function
    action: Function
  }[]

  private created() {
    this.isOrganizations = isUserOrganizations(
      this.userOrganizations,
      this.content.organizations
    )
    this.rebuildButtons()
  }
  private onEditClick() {
    this.$emit('edit', this.typeButton_.edit)
  }
  private onSaveClick() {
    this.$emit('save', this.statusButton)
  }
  private onDeleteClick() {
    this.$emit('delete', this.statusButton)
  }
  private onCloseClick() {
    this.$emit('close', this.statusButton)
  }
  private onCancelClick() {
    this.$emit('cancel', this.statusButton)
  }

  private rebuildButtons() {
    const buttons = [
      {
        title: 'Редактировать',
        icons: ['fas', 'edit'],
        name: 'Редактировать',
        variant: 'outline-primary',
        condition: () => {
          const ability = PermissionManager.common()
          this.canEditOrganization = ability.can(
            permissions.editOrganization,
            undefined
          )
          this.canEditNewsEvents = ability.can(
            permissions.editNewsEvents,
            undefined
          )
          return (
            this.statusButton === this.typeButton_.view && this.isUserButton
          )
        },
        action: () => {
          this.onEditClick()
        }
      },
      {
        title: 'Сохранить',
        icons: ['fas', 'save'],
        name: 'Сохранить',
        variant: 'outline-primary',
        condition: () => {
          return (
            this.statusButton === this.typeButton_.add ||
            this.statusButton === this.typeButton_.edit
          )
        },
        action: () => {
          this.onSaveClick()
        }
      },
      {
        title: 'Удалить',
        icons: ['fas', 'trash-alt'],
        name: 'Удалить',
        variant: 'outline-danger',
        condition: () => {
          return (
            this.statusButton === this.typeButton_.view &&
            this.content.type != contentType.organization &&
            this.isUserButton
          )
        },
        action: () => {
          this.onDeleteClick()
        }
      },
      {
        title: 'Закрыть',
        icons: ['fas', 'times'],
        name: 'Закрыть',
        variant: 'outline-danger',
        condition: () => {
          return this.statusButton === this.typeButton_.view
        },
        action: () => {
          this.onCloseClick()
        }
      },
      {
        title: 'Отмена',
        icons: ['fas', 'times'],
        name: 'Отмена',
        variant: 'outline-danger',
        condition: () => {
          return (
            this.statusButton === this.typeButton_.add ||
            this.statusButton === this.typeButton_.edit
          )
        },
        action: () => {
          this.onCancelClick()
        }
      }
    ]
    this.buttons = buttons.filter(b => b.condition())
  }

  private get isUserButton() {
    return (
      (this.canEditOrganization &&
        this.content.type === contentType.organization) ||
      (this.canEditNewsEvents &&
        (this.content.type === contentType.news ||
          this.content.type === contentType.event)) ||
      (!this.canEditNewsEvents &&
        this.isTeacher &&
        this.content.type === contentType.event &&
        this.isOrganizations)
    )
  }
}
