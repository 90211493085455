import TeacherAPI from '@/api/requests/teacher'
import { IGetRowsParams } from 'ag-grid-community'
import DataSource from '@/api/datasource/datasource'

function createFilter(filter: { [k: string]: any }) {
  if (filter.organizations) {
    filter['organizations.title'] = {
      filterType: 'text',
      type: filter.organizations.type,
      filter: filter.organizations.filter
    }
    delete filter.organizations
  }
  return { filter }
}

function createSort(sort: { [k: string]: any }) {
  if (sort.organizations) {
    const sorting = sort.organizations
    delete sort.organizations
    sort['organizations.title'] = sorting
  }
  return sort
}

export class EventParticipantsDataSource extends DataSource {
  constructor(contentObjectId: number) {
    super()
    this.contentObjectId = contentObjectId
  }

  private readonly contentObjectId!: number

  async getRows(params: IGetRowsParams) {
    const limit = params.endRow - params.startRow
    const offset = params.startRow
    const sort = this.castSortModel(params.sortModel)
    const filter = this.castFilterModel(params.filterModel)
    const newFilter = createFilter(filter)
    const newSort = createSort(sort)

    const response = await TeacherAPI.getParticipants(
      this.contentObjectId,
      limit,
      offset,
      newSort,
      newFilter
    )
    params.successCallback(response.data.rows, response.data.count)
  }
}
