





















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import AddEditContent from '@/components/content/helpers/AddEditContent.vue'
import PublicationButtons from '@/components/common/buttons/PublicationButtons.vue'
import { statusButton } from '@/config/content/statusButton'
import { maxLengthField } from '@/config/default/user'
import PublicateContentModal from '@/components/content/modal/PublicateContentModal.vue'
import ControlButtons from '@/components/common/buttons/ControlButtons.vue'
import SaveContentModal from '@/components/content/modal/SaveContentModal.vue'
import DeleteContentModal from '@/components/content/modal/DeleteContentModal.vue'
import CancelContentModal from '@/components/content/modal/CancelContentModal.vue'
import SingleContent from '@/components/content/SingleContent.vue'
import OrganizationsAPI from '@/api/requests/organizations'
import { toastMapper } from '@/store/modules/toast'
import ContentApi from '@/api/requests/content'
import { contentStatus, contentType } from '@/config/content/contentStatus'
import {
  PermissionManager,
  permissions
} from '@/api/permissions/PermissionManager'
import { userMapper } from '@/store/modules/user'
import { ContentAdapter } from '@/api/adapters/content'
import _ from 'lodash'
import { isUserOrganizations } from '@/utils/organizations'
import Config from '@/config/default/config'
import { Content } from '@/types/interfaces/content'

const Mapper = Vue.extend({
  computed: {
    ...userMapper.mapGetters([
      'isTeacher',
      'isContentManager',
      'userOrganizations'
    ])
  },
  methods: {
    ...toastMapper.mapActions(['pushToast'])
  }
})
@Component({
  components: {
    PublicationButtons,
    ControlButtons,
    AddEditContent,
    PublicateContentModal,
    SaveContentModal,
    DeleteContentModal,
    CancelContentModal,
    SingleContent
  }
})
export default class ContentViewer extends Mapper {
  @Prop(Object) content!: Content
  private id: number | string = this.$route.params.id
  private statusButton = ''
  private typeButtons_ = statusButton
  private viewContentManagerButtons = false
  private viewTeacherButton = false
  private contentType_ = contentType
  private organizations: any[] = []
  private isOrganizations = false
  private hasHistory = window.history.length > 1

  private errorText = ''

  @Watch('content')
  private onChangeContent() {
    if (
      this.content.type === this.contentType_.event ||
      this.contentType_.news
    ) {
      this.organizations = _.cloneDeep(this.content.organizations)
      this.isOrganizations = isUserOrganizations(
        this.userOrganizations,
        this.organizations
      )
    }
  }

  private created() {
    if (
      this.content.type === this.contentType_.event ||
      this.contentType_.news
    ) {
      this.organizations = _.cloneDeep(this.content.organizations)
      this.isOrganizations = isUserOrganizations(
        this.userOrganizations,
        this.organizations
      )
    }
    const ability = PermissionManager.common()
    this.viewContentManagerButtons = ability.can(
      permissions.viewContentManagerButtons,
      undefined
    )
    this.viewTeacherButton = ability.can(
      permissions.viewMyEventsButton,
      undefined
    )

    if (this.id === 'new') {
      this.statusButton = this.typeButtons_.add
    } else {
      this.statusButton = this.typeButtons_.view
    }
  }

  private showToast() {
    this.pushToast({
      time: 5,
      title: 'Добавление контента',
      message: 'Успешно добавлено!'
    })
  }

  private openPageFromContentType() {
    if (this.hasHistory) {
      if (
        this.content?.type === this.contentType_.news ||
        this.content?.type === this.contentType_.event
      ) {
        this.$router.go(-1)
        return
      } else {
        this.$router.push('/organizations')
        return
      }
    } else {
      this.$router.push(Config.routing.homePage.path)
    }
  }

  public buttonEdit(value: string) {
    this.statusButton = value
    this.$emit('onChangeStatusButton', value)
  }
  public buttonSave() {
    this.$bvModal.show('saveContentModalId')
  }
  public buttonDelete() {
    this.$bvModal.show(`deleteContentModalId-${this.content.id}`)
  }
  public buttonClose() {
    this.openPageFromContentType()
  }
  public buttonCancel() {
    this.$bvModal.show('cancelContentModalId')
  }

  public buttonPublicate() {
    this.$bvModal.show('publicateContentModalId')
    this.$emit('changeStatusButton', '')
  }

  private async modalPublicate() {
    const contentCopy = _.cloneDeep(this.content)
    if (contentCopy.type === this.contentType_.news) {
      contentCopy.eventDate = ContentAdapter.changeNewsDateFormatToServer(
        contentCopy.eventDate
      )
    }
    if (
      this.statusButton === this.typeButtons_.add &&
      this.isOkModel(contentCopy)
    ) {
      if (contentCopy.type === this.contentType_.event) {
        contentCopy.organizations = this.getIdOrganizations(contentCopy)
      }
      const response = await ContentApi.addContent(
        contentCopy,
        contentStatus.publicated
      )
      if (response.status === 201) {
        this.showToast()
      }
      this.$router.go(-1)
    } else if (
      this.statusButton === this.typeButtons_.edit &&
      this.isOkModel(contentCopy)
    ) {
      if (contentCopy.type === this.contentType_.event) {
        contentCopy.organizations = this.getIdOrganizations(contentCopy)
      }
      await ContentApi.editContent(
        contentCopy.id,
        contentCopy,
        contentCopy.status === contentStatus.publicated
          ? contentStatus.draft
          : contentStatus.publicated
      )
      this.$router.go(-1)
    } else if (this.statusButton === this.typeButtons_.view) {
      this.$emit('onChangeStatus')
    }
  }

  private async deleteContentButton() {
    await ContentApi.deleteContent(this.content.id)
    this.pushToast({
      time: 5,
      title: 'Удаление контента',
      message: `<strong>${this.content.title}</strong> успешно удалено!`
    })
    this.$router.go(-1)
  }

  private async modalSave() {
    this.$emit('changeStatusButton', '')
    const contentId = this.content.id
    const contentCopy = _.cloneDeep(this.content)

    if (contentCopy.type === this.contentType_.news) {
      contentCopy.eventDate = ContentAdapter.changeNewsDateFormatToServer(
        contentCopy.eventDate
      )
    }

    if (
      this.statusButton === statusButton.edit &&
      this.isOkModel(contentCopy)
    ) {
      this.statusButton = statusButton.view
      if (contentCopy.type === contentType.organization) {
        await OrganizationsAPI.editOrganization(contentId, contentCopy)
      } else {
        if (contentCopy.type === this.contentType_.event) {
          contentCopy.organizations = this.getIdOrganizations(contentCopy)
        }
        await ContentApi.editContent(
          contentCopy.id,
          contentCopy,
          contentCopy.status
        )
      }
    } else if (
      this.statusButton === this.typeButtons_.add &&
      this.isOkModel(contentCopy)
    ) {
      if (contentCopy.type === contentType.organization) {
        const response = await OrganizationsAPI.addOrganization(contentCopy)
        if (response.status === 201) {
          this.showToast()
        }
        this.$router.push('/organizations')
      } else {
        if (contentCopy.type === this.contentType_.event) {
          contentCopy.organizations = this.getIdOrganizations(contentCopy)
        }
        const response = await ContentApi.addContent(
          contentCopy,
          contentStatus.draft
        )
        if (response.status === 201) {
          this.showToast()
        }
        this.$router.go(-1)
      }
    }
  }

  private validateModel(content: Content) {
    this.errorText = ''

    // Проверка названия, существует у любого типа контента
    if (_.isEmpty(content.title) || content.title.length > maxLengthField) {
      this.errorText = 'Некорректное название'
      return
    }

    // Проверка краткого описания, существует у любого типа контента
    if (content.shortDescription.length > maxLengthField) {
      this.errorText = `Длина краткого описания больше, чем ${maxLengthField} символов. Пожалуйста, сократите текст.`
      return
    }

    // Проверка полей организации
    if (content.type === this.contentType_.organization) {
      if (_.isEmpty(content.organizationType)) {
        this.errorText = 'Не выбран тип организации'
        return
      }
    }

    // Проверка полей новости и/или события
    if (
      content.type === this.contentType_.event ||
      content.type === this.contentType_.news
    ) {
      if (
        _.isEmpty(content.eventDate) ||
        content.eventDate === 'Invalid date'
      ) {
        this.errorText = 'Не верно указана дата'
        return
      }
    }

    // Проверка полей события
    if (content.type === this.contentType_.event) {
      if (
        this.isTeacher &&
        this.isContentManager &&
        _.isEmpty(content.organizations)
      ) {
        this.errorText = 'Не выбрана организация'
        return
      }
    }
  }

  private isOkModel(content: Content) {
    this.validateModel(content)

    if (this.errorText) {
      this.pushToast({
        error: true,
        title: 'Ошибка',
        message: this.errorText,
        time: 5
      })
    }
    return !this.errorText
  }

  private modalCancel() {
    this.$emit('changeStatusButton', '')
    if (this.statusButton === statusButton.edit) {
      this.statusButton = statusButton.view
    } else {
      this.openPageFromContentType()
    }
  }

  private getIdOrganizations(content: Content) {
    return !content.organizations.find((el: any) => el.title === 'Все')
      ? content.organizations.map((ourOrg: any) => ourOrg.id)
      : []
  }
}
