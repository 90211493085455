



















import { Vue, Component, Prop } from 'vue-property-decorator'
import {
  contentStatus,
  publicationButtonTranslation
} from '@/config/content/contentStatus'

@Component
export default class PublicateContentModal extends Vue {
  @Prop(String) status!: contentStatus
  private publicationButtonTranslation_ = publicationButtonTranslation

  private onPublicateContent() {
    this.$emit('modalPublicate')
  }
}
